import { Component, OnInit } from '@angular/core';
import { ModalContent, ModalRef } from '../../../modal-ref';
import {
  GoogleTagManagerService,
  POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY,
} from '../../../../services/google-tag-manager.service';
import { Payment, RedirectRoutes } from 'payments-lib-types';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import {
  PaymentSsComponent,
} from '../../../../../page/account/account-cashier/payment-tab/components/payment-ss/payment-ss.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-deposit-modal',
  templateUrl: './deposit-modal.component.html',
  styleUrls: ['./deposit-modal.component.scss'],
  standalone: true,
  imports: [TranslatePipe, PaymentSsComponent, NgClass],
})
export class DepositModalComponent implements ModalContent, OnInit {

  public readonly DepositAction: Payment.Action = Payment.Action.Deposit;

  public CustomDepositModalRoutes: RedirectRoutes;

  public isCryptoSelected: boolean;

  public Action: Payment.Action = Payment.Action.Deposit;

  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  constructor(private _gtm: GoogleTagManagerService) {
  }

  ngOnInit() {
    this._initCustomRoutes();
    this._gtm.popupEvent(
      POPUP_EVENT_CATEGORY.DEPOSIT_MODAL,
      POPUP_EVENT_ACTIONS.DEPOSIT_MODAL
    );
  }

  /**
   * Get modal content
   */
  get modalData(): any {
    return this.modal.options.data;
  }

  private _initCustomRoutes() {
    this.CustomDepositModalRoutes = {
      successUrl: `${location.origin}/${this.modalData}`,
      failureUrl: `${location.origin}/payment-status/${this.DepositAction}/failure`,
      supportUrl: `${location.origin}/faq`,
      pendingUrl: `${location.origin}/${this.modalData}`,
    };
  }

}
